import React from "react"

export const TextFormater = (text)=> {
  const splitedForZeroText = text.split(
    new RegExp("<0>|</0>", "g")
  )
  const formatedForZeroText = splitedForZeroText.map((text, i) => {
    if (i === 0 || i % 2 === 0 || i === splitedForZeroText.length)
      return text

    return <sup key={i}>{text}</sup>
  })
  return formatedForZeroText;
}
