import React from "react"

import enJSON from "../../public/locales/en/translation"
import trJSON from "../../public/locales/tr/translation"

export const getLanguageFile = language => {
  if (language === "en") {
    const formatedEnJSON = {}
    Object.keys(enJSON).forEach(eachKey => {
      formatedEnJSON[eachKey] = {}
      Object.keys(enJSON[eachKey]).forEach(eachSubKey => {
        const splitedForZeroText = enJSON[eachKey][eachSubKey].split(
          new RegExp("<0>|</0>", "g")
        )
        const formatedForZeroText = splitedForZeroText.map((text, i) => {
          if (i === 0 || i % 2 === 0 || i === splitedForZeroText.length)
            return text

          return <sup key={i}>{text}</sup>
        })

        const formatedText = formatedForZeroText.map(eachText => {
          if (typeof eachText === "string") {
            const splitedForOneEachText = eachText.split(
              new RegExp("<1>|</1>", "g")
            )
            const formatedForOnEachText = splitedForOneEachText.map(
              (text, i) => {
                if (i === 0 || i % 2 === 0 || i === splitedForZeroText.length)
                  return text

                  if(text === "here" ){
                    if(eachSubKey ==="nginrDescriptionPartFour"){
                     return( <a
                      style={{
                        color: "#8f8f8f",
                        fontWeight: 600,
                      }}
                      href="/booklets/nginrsBooklet.pdf"
                      key={i}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {text}
                    </a>)
                    }else if(eachSubKey ==="nginrDescriptionPartFive"){
                      return(
                      <a
                      style={{
                        color: "#8f8f8f",
                        fontWeight: 600,
                      }}
                      href="/booklets/nginrmBooklet.pdf"
                      key={i}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {text}
                    </a>)
                    } else if(eachSubKey ==="hymotsDescriptionPartFive"){
                        return(
                        <a
                        style={{
                          color: "#8f8f8f",
                          fontWeight: 600,
                        }}
                        href="/booklets/Hymots_Katalog_v1_r4.pdf"
                        key={i}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {text}
                      </a>)
                    } else if(eachSubKey === "etecubeBooklet"){
                      return(
                        <a
                        style={{
                          color: "#8f8f8f",
                          fontWeight: 600,
                        }}
                        href="/booklets/ETECubeBooklet.pdf"
                        key={i}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {text}
                      </a>)
                    } 
                    else {
                        return(
                          <a
                          style={{
                            color: "#8f8f8f",
                            fontWeight: 600,
                          }}
                          href="./booklets/ETECube.pdf"
                          key={i}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {text}
                        </a>
                        )
                    }
                }
              }
            )
            return formatedForOnEachText
          }

          return eachText
        })

        formatedEnJSON[eachKey][eachSubKey] = formatedText
      })
    })

    return formatedEnJSON
  } else {
    const formatedTrJSON = {}
    Object.keys(trJSON).forEach(eachKey => {
      formatedTrJSON[eachKey] = {}
      Object.keys(trJSON[eachKey]).forEach(eachSubKey => {
        const splitedForZeroText = trJSON[eachKey][eachSubKey].split(
          new RegExp("<0>|</0>", "g")
        )
        const formatedForZeroText = splitedForZeroText.map((text, i) => {
          if (i === 0 || i % 2 === 0 || i === splitedForZeroText.length)
            return text

          return <sup key={i}>{text}</sup>
        })

        const formatedText = formatedForZeroText.map(eachText => {
          if (typeof eachText === "string") {
            const splitedForOneEachText = eachText.split(
              new RegExp("<1>|</1>", "g")
            )

            const formatedForOnEachText = splitedForOneEachText.map(
              (text, i) => {
                if (i === 0 || i % 2 === 0 || i === splitedForZeroText.length)
                  return text

                  if(text === "burada" ){
                    if(eachSubKey ==="nginrDescriptionPartFour"){
                     return( <a
                      style={{
                        color: "#8f8f8f",
                        fontWeight: 600,
                      }}
                      href="/booklets/nginrsBooklet.pdf"
                      key={i}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {text}
                    </a>)
                    }else if(eachSubKey ==="nginrDescriptionPartFive"){
                      return(
                      <a
                      style={{
                        color: "#8f8f8f",
                        fontWeight: 600,
                      }}
                      href="/booklets/nginrmBooklet.pdf"
                      key={i}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {text}
                    </a>)
                    } else if(eachSubKey ==="hymotsDescriptionPartFive"){
                        return(
                        <a
                        style={{
                          color: "#8f8f8f",
                          fontWeight: 600,
                        }}
                        href="/booklets/Hymots_Katalog_v1_r4.pdf"
                        key={i}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {text}
                      </a>)
                    } else if(eachSubKey === "etecubeBooklet"){
                      return(
                        <a
                        style={{
                          color: "#8f8f8f",
                          fontWeight: 600,
                        }}
                        href="/booklets/ETECubeBooklet.pdf"
                        key={i}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {text}
                      </a>)
                    } else {
                        return(
                          <a
                          style={{
                            color: "#8f8f8f",
                            fontWeight: 600,
                          }}
                          href="./booklets/ETECube.pdf"
                          key={i}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {text}
                        </a>
                       )
                      }
                  }
                }
              )

            return formatedForOnEachText
          }

          return eachText
        })

        formatedTrJSON[eachKey][eachSubKey] = formatedText
      })
    })

    return formatedTrJSON
  }
}
